:root {
  --safe-area-inset-top: 12px !important;
}

[data-rsbs-overlay] {
  box-shadow: none !important;
  right: 0;
  width: 520px !important;
  max-height: 80vh !important;
  margin: 5vh auto !important;
  z-index: 3 !important;
  display: block;
  border-radius: 0px !important;
}

[data-rsbs-content] {
  padding: 24px !important;
}

[data-rsbs-backdrop] {
  background-color: var(--grey-color-with-opacity) !important;
  width: 520px !important;
  margin: 5vh auto !important;
  height: 90vh !important;
  right: 0 !important;
  top: 0 !important;
}

[data-rsbs-scroll] {
  border-top: 1px solid white !important;
  height: 86%;
  z-index: 2 !important;
}

[data-rsbs-scroll]::-webkit-scrollbar {
  display: none;
}

[data-rsbs-header]:before {
  top: 15px !important;
  background-color: #e725e1 !important;
  width: 42.0px !important;
  transform: translateX(-50%) scaleY(1) !important;
  border-radius: 0px !important;
}

@media (max-width: 520px) {
  [data-rsbs-backdrop] {
    height: 100vh !important;
    margin: 0vh auto !important;
    width: 100% !important;
  }

  [data-rsbs-overlay] {
    margin: 0vh auto !important;
    width: 100% !important;
  }

  [data-rsbs-scroll] {
    height: 88%;
  }
}