.sharedDrawer {
    right: 70px;
    margin: 0 auto;
    width: 450px !important;
    height: 90vh !important;
    top: 5vh !important;
    box-shadow: none !important;
}

.EZDrawer__overlay {
    background-color: var(--grey-color-with-opacity) !important;
    width: 520px !important;
    right: 0;
    margin: 0 auto;
    height: 90vh !important;
    top: 5vh !important;
}

@media (max-width: 520px) {
    .sharedDrawer {
        height: 100vh !important;
        left: none !important;
        right: none !important;
        width: 80% !important;
        top: 0 !important;
        left: 0 !important;
        right: none !important;
        margin: 0 !important;
    }

    .EZDrawer__overlay {
        height: 100vh !important;
        width: 100% !important;
        top: 0 !important;
    }
}

.sharedDrawer {
    transition-duration: 300ms !important;
    transform: translate3d(-20%, 0px, 0px) !important;
}