:root {
    --grey-color-with-opacity: rgba(218, 220, 224, 0.42);
}

.MuiFileInput-placeholder {
    color: black !important;
    font-weight: 300;
}

.MuiFormControlLabel-label {
    font-weight: 300 !important;
}

.Mui-disabled {
    opacity: 1 !important;
}

.Toastify__toast {
    border-radius: 0px;
}